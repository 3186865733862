.carousel-component {
	.carousel {
		display: inline-flex;
		overflow-x: hidden;
		scroll-snap-type: x mandatory;
		-webkit-overflow-scrolling: touch;
		scrollbar-width: none;
		-ms-overflow-style: none;
		overflow: hidden;
		height: 100%;
	}
}
